<template>
  <div class="top-box">
    <div class="item1" v-for="(item,index) in topGameList" :key="index" @click="detailsClick(item)">
      <a :href="'/#/M/details?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item.iconUrl" alt=""><span>{{ item.Name }}</span></a>
    </div>
  </div>
</template>

<script>
import {clickGameLog, Observer, recentGame} from '@/utils/utils.js';
export default {
  name: "TopBox",
  props: ['topGameList'],
  data() {
    return {

    }
  },
  mounted() {
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("item1")]
      itemArr && Array.from(itemArr).map((item)=>{
        Observer().observe(item)
      })
    })
  },
  methods: {
    detailsClick(item) {
      clickGameLog(item)
      recentGame(item)
      // this.$router.push({
      //   path: '/M/details',
      //   query: {
      //     gameId: item.gameId
      //   }
      // },()=>{})
    }
  }
}
</script>

<style lang="less" scoped>
.top-box{
  padding: 0 0.625rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  //margin-top: 3.375rem;
  .item1{
    box-sizing: border-box;
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 0.375rem;
    overflow: hidden;
    border: 2px solid #fff;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(0 0 0 / 60%);
    img{
      width: 100%;
      height: 100%;
      background: white;
    }
    span{
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0;
    }
  }
}
</style>
