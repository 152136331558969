<template>
  <div>
    <div class="app-list" v-for="(item,index) in appGameList" :key="index">
      <div class="app-list-content">
        <div class="content-top">
          <div class="item" @click="detailsClick(item[0])" v-if="item[0] && item[0].iconUrl"><a :href="'/#/M/details?gameId='+item[0].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[0] && item[0].iconUrl" alt=""></a><span>{{ item[0].Name }}</span></div>
          <div class="item" @click="detailsClick(item[1])" v-if="item[1] && item[1].iconUrl"><a :href="'/#/M/details?gameId='+item[1].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[1] && item[1].iconUrl" alt=""></a><span>{{ item[1].Name }}</span></div>
          <div class="item" @click="detailsClick(item[2])" v-if="item[2] && item[2].iconUrl"><a :href="'/#/M/details?gameId='+item[2].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[2] && item[2].iconUrl" alt=""></a><span>{{ item[2].Name }}</span></div>
        </div>
        <div class="adv" v-if="index === 1" ref="homeBottomAdv">
          <div id="taboola-below-article-thumbnails5"></div>
        </div>
        <div class="content-middle" v-if="(index + 1) % 2 !== 0">
          <div class="middle-left" v-if="item[3] && item[3].iconUrl">
            <div class="item" @click="detailsClick(item[3])"><a :href="'/#/M/details?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[3] && item[3].iconUrl" alt=""></a><span>{{ item[3].Name }}</span></div>
          </div>
          <div class="middle-right">
            <div class="item-box" v-if="item[4] && item[4].iconUrl"><div class="item" @click="detailsClick(item[4])"><a :href="'/#/M/details?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[4] && item[4].iconUrl" alt=""></a><span>{{ item[4].Name }}</span></div></div>
            <div class="item-box" v-if="item[5] && item[5].iconUrl"><div class="item" @click="detailsClick(item[5])"><a :href="'/#/M/details?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[5] && item[5].iconUrl" alt=""></a><span>{{ item[5].Name }}</span></div></div>
          </div>
          <div></div>
        </div>
        <div class="content-middle" v-if="(index + 1) % 2 === 0">
          <div class="middle-right">
            <div class="item-box" v-if="item[3] && item[3].iconUrl"><div class="item" @click="detailsClick(item[3])"><a :href="'/#/M/details?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[3] && item[3].iconUrl" alt=""></a><span>{{ item[3].Name }}</span></div></div>
            <div class="item-box" v-if="item[4] && item[4].iconUrl"><div class="item" @click="detailsClick(item[4])"><a :href="'/#/M/details?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[4] && item[4].iconUrl" alt=""></a><span>{{ item[4].Name }}</span></div></div>
          </div>
          <div class="middle-left2" v-if="item[5] && item[5].iconUrl">
            <div class="item" @click="detailsClick(item[5])"><a :href="'/#/M/details?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[5] && item[5].iconUrl" alt=""></a><span>{{ item[5].Name }}</span></div>
          </div>
          <div></div>
        </div>
        <div class="adv" v-if="index === 0" ref="homeAdv">
          <div id="taboola-below-article-thumbnails3"></div>
<!--          <h6 class="adv-title">Advertisement</h6>-->
<!--          <div style="margin-top: 0.5625rem">-->
<!--            <ins class="adsbygoogle"-->
<!--                 style="display:block"-->
<!--                 data-ad-client="ca-pub-9543177256867205"-->
<!--                 data-ad-slot="6291814982"-->
<!--                 data-ad-format="auto"-->
<!--                 data-full-width-responsive="true"></ins>-->
<!--          </div>-->
        </div>
        <div class="content-top" style="margin-top: 0.5625rem">
          <div class="item" @click="detailsClick(item[6])" v-if="item[6] && item[6].iconUrl"><a :href="'/#/M/details?gameId='+item[6].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[6] && item[6].iconUrl" alt=""></a><span>{{ item[6].Name }}</span></div>
          <div class="item" @click="detailsClick(item[7])" v-if="item[7] && item[7].iconUrl"><a :href="'/#/M/details?gameId='+item[7].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[7] && item[7].iconUrl" alt=""></a><span>{{ item[7].Name }}</span></div>
          <div class="item" @click="detailsClick(item[8])" v-if="item[8] && item[8].iconUrl"><a :href="'/#/M/details?gameId='+item[8].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[8] && item[8].iconUrl" alt=""></a><span>{{ item[8].Name }}</span></div>
        </div>
        <div class="content-bottom">
          <div class="item-box" v-if="item[9] && item[9].iconUrl"><div class="item" @click="detailsClick(item[9])"><a :href="'/#/M/details?gameId='+item[9].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[9] && item[9].iconUrl" alt=""></a><span>{{ item[9].Name }}</span></div></div>
          <div class="item-box" v-if="item[10] && item[10].iconUrl"><div class="item" @click="detailsClick(item[10])"><a :href="'/#/M/details?gameId='+item[10].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"><img v-lazy="item[10] && item[10].iconUrl" alt=""></a><span>{{ item[10].Name }}</span></div></div>
        </div>
      </div>
    </div>
    <div class="adv">
<!--      <div>-->
<!--        <ins class="adsbygoogle"-->
<!--             style="display:block"-->
<!--             data-ad-client="ca-pub-9543177256867205"-->
<!--             data-ad-slot="1039488300"-->
<!--             data-ad-format="auto"-->
<!--             data-full-width-responsive="true"></ins>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {clickGameLog, Observer, recentGame} from '@/utils/utils.js';
export default {
  name: "AppList",
  props: ['appGameList'],
  data() {
    return {

    }
  },
  mounted() {
    // setTimeout(()=>{
    //   window.addAds()
    // },1300)
    setTimeout(()=>{
      let homeAdv = document.createElement("script")
      homeAdv.innerHTML = "window._taboola = window._taboola || [];\n" +
          "  _taboola.push({\n" +
          "    mode: 'thumbs-feed-01',\n" +
          "    container: 'taboola-below-article-thumbnails3',\n" +
          "    placement: 'Below Article Thumbnails_mob_home' + (channelName ? '_' : '') + (channelName ? channelName : ''),\n" +
          "    target_type: 'mix'\n" +
          "  });\n"

      this.$refs.homeAdv[0].append(homeAdv)

      window._taboola = window._taboola || [];
      _taboola.push({flush: true});
    })
    setTimeout(()=>{
      let homeBottomAdv = document.createElement("script")
      homeBottomAdv.innerHTML = "window._taboola = window._taboola || [];\n" +
          "  _taboola.push({\n" +
          "    mode: 'thumbs-feed-01',\n" +
          "    container: 'taboola-below-article-thumbnails5',\n" +
          "    placement: 'Below Article Thumbnails_mob_home2' + (channelName ? '_' : '') + (channelName ? channelName : ''),\n" +
          "    target_type: 'mix'\n" +
          "  });\n"

      this.$refs.homeBottomAdv[0].append(homeBottomAdv)

      window._taboola = window._taboola || [];
      _taboola.push({flush: true});
    })
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("item")]
      itemArr && Array.from(itemArr).map((item)=>{
        Observer().observe(item)
      })
    })
  },
  methods: {
    detailsClick(item) {
      clickGameLog(item)
      recentGame(item)
      // this.$router.push({
      //   path: '/M/details',
      //   query: {
      //     gameId: item.gameId
      //   }
      // },()=>{})
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.app-list{
  margin-top: 0.5625rem;
  .app-list-content{
    padding: 0 0.625rem;
    .content-top{
      width: 100%;
      box-sizing: border-box;
      .item{
        float: left;
        width: 5.875rem;
        height: 5.875rem;
        border-radius: 0.875rem;
        overflow: hidden;
        border: 2px solid #fff;
        img{
          width: 100%;
          height: 100%;
          background: white;
        }
      }
      .item:not(:last-child){
        margin-right: 0.561rem;
      }
    }
    .content-top:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .content-middle{
      margin-top: 0.5625rem;
      margin-bottom: 0.5625rem;
      .middle-left{
        float: left;
        margin-right: 0.561rem;
        width: 12.3125rem;
        height: 12.3125rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .middle-left2{
        float: left;
        margin-left: 0.561rem;
        width: 12.3125rem;
        height: 12.3125rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .middle-right{
        float: left;
        .item-box{
          width: 5.875rem;
          height: 5.875rem;
          display: block;
          position: relative;
          background-color: #fff;
          box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.6);
          border-radius: 0.875rem;
          .item{
            width: 100%;
            height: 100%;
            border-radius: 0.875rem;
            overflow: hidden;
            border: 2px solid #fff;
            img{
              width: 100%;
              height: 100%;
              background: white;
            }
            span{
              position: absolute;
              right: 0px;
              bottom: 0px;
              left: 0px;
              opacity: 0;
            }
          }
        }
        .item-box:not(:first-child){
          margin-top: 0.5625rem;
        }
      }
    }
    .content-middle:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .content-bottom{
      margin-top: 0.5625rem;
      .item-box{
        float: left;
        width: 9.0625rem;
        height: 9.0625rem;
        display: block;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.6);
        border-radius: 0.875rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .item-box:not(:first-child){
        margin-left: 0.624rem;
      }
    }
    .content-bottom:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  .adv{
    margin-top: 0.5625rem;
    .adv-title{
      background-color: #f7f7f7;
      line-height: 1.4375rem;
      font-size: .8125rem;
      color: #333;
      text-align: center;
    }
  }
}
</style>
